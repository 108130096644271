import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { downloadFile, generateErrors } from '@/main/utils/common';

export const getFormTemplates = async ({ formId, page = 1, pageSize = PAGE_SIZE, relationShip = false, filter = {} }) => {
    try {
        const { data } = await axios.get(`${apiUrl}/form/${formId}/relationships/templates`, { params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                include: relationShip ? 'worksheets,groups,form' : ''
            }
        });
        return data;

    } catch (error) {
        generateErrors(error);
    }
};

export async function createNewFormTemplate(formId, data) {
    try {
        const response = await axios.post(`${apiUrl}/form/${formId}/relationships/templates`, data)
        return response
    } catch (error) {
        generateErrors(error);
    }
}

export async function copyFormTemplate(formId, data) {
    try {
        const response = await axios.post(`${ apiUrl }/form-template/${ formId }/clone?include=form,worksheets`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export async function patchFormTemplate(formTemplateId, data) {
    try {
        const response = await axios.patch(`${apiUrl}/form-template/${formTemplateId}`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const downloadXlsFormTemplate = async (formTemplateId, format = 'xlsx') => {
    try {
        const { data } = await axios.get(`${apiUrl}/form/${formTemplateId}/relationships/templates/download/${format}`, {
            responseType: 'blob'
        })
        downloadFile(formTemplateId, data)
    } catch (error) {
        generateErrors(error)
    }

};

export const downloadConstructorTemplateXLS = async (formTemplateId, fileName, format = 'xlsx') => {
    try {
        const res = await axios.get(`${apiUrl}/form-template/${formTemplateId}/download-spreadsheet/${format}`, {
            responseType: 'blob'
        })
        downloadFile(fileName, res.data)
    } catch (error) {
        generateErrors(error)
    }

};
export const uploadConstructorXLS = async (formTemplateId, file) => {
    try {
        const result = await axios.post(`${apiUrl}/form-template/${formTemplateId}/upload-spreadsheet`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return result
    } catch (error) {
        generateErrors(error)
    }

};

export const getTemplateData = async (templateId) => {
    try {
        const { data: { data, included, data: { relationships } } } = await axios.get(`${ apiUrl }/form-template/${ templateId }`, {
            params: {
                include: 'worksheets.group,form.distributionInterval,form.groups,form,worksheets.template'
            }
        });

        return {
            data: data,
            relationships: relationships,
            included: included
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const editWorkSheetGroup = async (formTemplateGroupId, data) => {
    try {
        const request = await axios.patch(`${apiUrl}/form-template-group/${formTemplateGroupId}`, data);

        return request
    } catch (error) {
        generateErrors(error);
    }
};
export const deleteWorkSheetGroup = async (formTemplateGroupId) => {
    try {
        const request = await axios.delete(`${apiUrl}/form-template-group/${formTemplateGroupId}`);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};
export const createWorkSheet = async (formTemplateId, dataToServer) => {
    try {
        const { data } = await axios.post(`${ apiUrl }/form-template/${ formTemplateId }/relationships/worksheets`, dataToServer);

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const editWorksheet = async (worksheetId, data) => {
    try {
        const request = await axios.patch(`${ apiUrl }/form-template-worksheet/${ worksheetId }`, data);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};
export const deleteWorkSheet = async (worksheetId) => {
    try {
        const request = await axios.delete(`${ apiUrl }/form-template-worksheet/${ worksheetId }`);

        return request
    } catch (error) {
        generateErrors(error);
    }
};
export const getWorkSheetCells = async (worksheetId, version = '') => {
    const params = {
        sort: '-row',
        include: 'layers,worksheet'
    }

    if (version.length) {
        params.version = version;
    }

    try {
        const { data } = await axios.get(`${ apiUrl }/form-template-worksheet/${ worksheetId }/relationships/cells`, {
            params
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const getVisibilityRules = async (worksheetId) => {
    try {
        let { data: { data } } = await axios.get(`${ apiUrl }/form-template-worksheet/${ worksheetId }/relationships/visibility-rules`);

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const validateCellLayer = async (worksheetId, data) => {
    try {
        const { data: response } = await axios.post(`${ apiUrl }/form-template-worksheet/${ worksheetId }/validate-cell-layer`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export const saveWorkSheetCells = async (worksheetId, dataToServer, currentVersion) => {
    const currentVersionString = currentVersion ? `?currentVersion=${currentVersion}` : '';

    try {
        const { status } = await axios.patch(`${ apiUrl }/form-template-worksheet/${ worksheetId }/content${ currentVersionString }`, dataToServer)

        return status
    } catch (error) {
        generateErrors(error);
    }
};
export const getWorkSheetVersions = async (worksheetId) => {
    try {
        const request = await axios.get(`${ apiUrl }/form-template-worksheet/${ worksheetId }/relationships/versions?include=createdBy`)

        return request;
    } catch (error) {
        generateErrors(error);
    }
};


export const uploadFormTemplateFile = async (formTemplateId, file) => {
    try {
        await axios.post(`${apiUrl}/form-template/${formTemplateId}/upload-spreadsheet`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        generateErrors(error);
    }
}

export const aggregate = async (templateId, data) => {
    try {
        const request = await axios.post(`${apiUrl}/form-template/${templateId}/aggregate`, data);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const aggregateByFilter = async (templateId, data) => {
    try {
        const request = await axios.post(`${apiUrl}/form-template/${templateId}/aggregate-by-filter`, data);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const getAggregateResults = async (aggregateId, worksheetId, refresh = 'n', calc = '&calc=0') => {
    try {
        const { data } = await axios.get(
            `${ apiUrl }/form-aggregate/${ aggregateId }/relationships/results?worksheetId=${ worksheetId }&refresh=${ refresh }${ calc }`
        );

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const downloadCheckLayers = async (templateId) => {
    try {
        return await axios.get(
            `${ apiUrl }/form-template/${ templateId }/list-check-layers`
        );
    } catch (error) {
        generateErrors(error);
    }
};
